import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import styles from "@/styles/infScroll.module.scss";

import CloudFlareIcon from "@/components/MuiIcons/CloudFlareIcon";
import WorldCoinIcon from "@/components/MuiIcons/WorldCoin";
import AmazonIcon from "@/components/MuiIcons/AmazonIcon";
import GoogleIcon from "@/components/MuiIcons/GoogleIcon";
import DeliveryHeroIcon from "@/components/MuiIcons/DeliveryHeroIcon";

const InfiniteCarousel = () => {
  return (
    <>
      <div className={styles.logos}>
        <div className={styles.scroll}>
          <DeliveryHeroIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <GoogleIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <AmazonIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <WorldCoinIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <CloudFlareIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
        </div>
        <div className={styles.scroll}>
          <DeliveryHeroIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <GoogleIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <AmazonIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <WorldCoinIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
          <CloudFlareIcon
            sx={{
              height: "5rem",
              width: "auto",
              marginLeft: "4rem",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InfiniteCarousel;
