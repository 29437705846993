"use client";

import { useTranslations } from "next-intl";
import Link from "next/link";

import styles from "@/styles/page.module.scss";

import { AnimatedCard } from "@/components/AnimatedCard";
import InfiniteCarousel from "@/components/InfiniteCarousel";
import SignUpForm from "@/components/SignUpForm";

import HydraIcon from "@/components/MuiIcons/HydraIcon";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import BranchIcon from "@/components/MuiIcons/BranchIcon";
import BulbIcon from "@/components/MuiIcons/BulbIcon";
import ServiceIcon from "@/components/MuiIcons/ServiceIcon";
import RibbonIcon from "@/components/MuiIcons/RibbonIcon";

export default function Home() {
  const t = useTranslations("Landing");

  return (
    <>
      <main>
        <section id="hero" className={[""].join(" ")}>
          <div className="inset-0 -z-10 absolute flex items-center content-center w-full">
            <div className="relative w-full h-full">
              <div className="w-full h-full absolute md:right-auto top-[35vh] md:mt-[15rem] md:top-auto md:mt-[15rem]">
                <BranchIcon
                  className="h-[1102px]"
                  sx={{
                    height: "90vh",
                    width: "auto",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={[styles.homeSection, "flexWrap"].join(" ")}>
            <div
              className={[
                styles.heroSectionCTA,
                "",
              ].join(" ")}
            >
              <h1>{t("hero-title")}</h1>

              <h2>{t("hero-cta")}</h2>

              <Link autoFocus={false} href="#contact">
                <Button autoFocus={false}>{t("hero-cta-button")} </Button>
              </Link>
            </div>
            <div className="flex w-full place-content-end md:block md:w-auto  mb-[1.5rem]">
              <div className="w-fit content-end mr-[10vw] md:content-normal md:m-0"></div>
              <AnimatedCard rotation={18} afterRotation={12} rotationHover={0}>
                <div className="h-[20vh] md:h-[35vh] flex justify-center items-center p-[1rem] md:pt-[5rem] md:pb-[5rem]">
                  <HydraIcon
                    style={{
                      width: "auto",
                      maxWidth: "calc(100vw - 10rem)",
                      height: "100%",
                    }}
                  />
                </div>
              </AnimatedCard>
            </div>
          </div>
        </section>
        <section id={"howTo"} className={[styles.howToBanner].join(" ")}>
          <div className={[styles.howToSection].join(" ")}>
              <div className={[styles.howToContent, " md:w-[70rem] md:px-2 pt-8"].join(" ")}>
                <div className={styles.content_logo}>
                  <HydraIcon sx={{ width: "auto", height: "auto" }} />
                </div>
                <h1>{t("howTo-title")}</h1>
              </div>
              <div className={[styles.howToTrophies].join(" ")}>
                <Paper className={styles.howToPaper}>
                  <h2>{t("howTo-first-trophy-title")}</h2>
                  <BulbIcon
                    sx={{ width: "4rem", height: "4rem", marginBottom: "2rem" }}
                  />
                  <h3>{t("howTo-first-trophy-paragraph")}</h3>
                </Paper>
                <Paper className={styles.howToPaper}>
                  <h2>{t("howTo-second-trophy-title")}</h2>
                  <ServiceIcon
                    sx={{ width: "4rem", height: "4rem", marginBottom: "2rem" }}
                  />
                  <h3>{t("howTo-second-trophy-paragraph")}</h3>
                </Paper>
                <Paper className={styles.howToPaper}>
                  <h2>{t("howTo-third-trophy-title")}</h2>
                  <RibbonIcon
                    sx={{ width: "4rem", height: "4rem", marginBottom: "2rem" }}
                  />
                  <h3>{t("howTo-third-trophy-paragraph")}</h3>
                </Paper>
              </div>
            </div>
        </section>
        <section id={"clients"} className={[styles.clientsBanner].join(" ")}>
          <div className={[styles.clientsSection].join(" ")}>
            <h1>{t("clients-title")}</h1>
            <div className={[styles.clientCarousel].join(" ")}>
              <InfiniteCarousel />
            </div>
          </div>
        </section>
        <section id="contact" className={["overflow-hidden"].join(" ")}>
          <div className={[styles.signUpSection, "maxWidth"].join(" ")}>
            <div>
              <h1>{t("signUp-title")}</h1>
              <h2>{t("signUp-cta")}</h2>
            </div>
            <div className={styles.titles}>
              <SignUpForm />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
