import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "AmazonIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})(() => ({
  fill: "none",
  stroke: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
}));

const AmazonIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 316 96" focusable="false">
      <path
        d="M196.039 74.4716C177.675 88.0082 151.059 95.2277 128.141 95.2277C96.0099 95.2277 67.0794 83.3439 45.1953 63.5794C43.4744 62.0264 45.0169 59.9067 47.0789 61.1187C70.6944 74.8599 99.8977 83.1287 130.066 83.1287C150.408 83.1287 172.791 78.9209 193.368 70.1851C196.479 68.8629 199.077 72.2208 196.039 74.4769"
        fill="#6A488E"
      />
      <path
        d="M203.673 65.7365C201.333 62.7353 188.158 64.3198 182.24 65.0229C180.44 65.2433 180.162 63.6745 181.783 62.5464C192.277 55.159 209.502 57.2945 211.506 59.7657C213.516 62.2579 210.981 79.5196 201.123 87.757C199.612 89.0214 198.164 88.3498 198.841 86.6709C201.055 81.1408 206.023 68.7481 203.668 65.7365"
        fill="#6A488E"
      />
      <path
        d="M182.654 10.3985V3.21576C182.654 2.12969 183.478 1.40039 184.47 1.40039H216.616C217.65 1.40039 218.474 2.14543 218.474 3.21576V9.36493C218.458 10.3985 217.592 11.7469 216.055 13.8771L199.397 37.6605C205.588 37.5084 212.12 38.4318 217.734 41.5956C218.998 42.3091 219.345 43.3585 219.439 44.3868V52.0471C219.439 53.0912 218.285 54.3189 217.073 53.684C207.183 48.5003 194.045 47.9336 183.111 53.7365C181.999 54.3399 180.828 53.1331 180.828 52.0838V44.8066C180.828 43.6366 180.844 41.6428 182.009 39.8694L201.306 12.1929H184.512C183.478 12.1929 182.654 11.4636 182.654 10.3933"
        fill="#6A488E"
      />
      <path
        d="M65.39 55.2012H55.6101C54.6762 55.133 53.9311 54.4299 53.8629 53.538V3.3425C53.8629 2.34038 54.7024 1.54287 55.7465 1.54287H64.8653C65.815 1.58485 66.5705 2.31414 66.6387 3.22183V9.78548H66.8171C69.1991 3.44219 73.6693 0.488281 79.6926 0.488281C85.7158 0.488281 89.6351 3.44744 92.3896 9.78548C94.7559 3.44219 100.134 0.488281 105.895 0.488281C109.992 0.488281 114.478 2.17773 117.217 5.97636C120.313 10.2 119.678 16.3334 119.678 21.7113L119.662 53.391C119.662 54.3932 118.823 55.2064 117.779 55.2064H108.014C107.039 55.1382 106.252 54.3512 106.252 53.391V26.7901C106.252 24.6705 106.446 19.3922 105.979 17.3827C105.249 14.0143 103.061 13.0647 100.228 13.0647C97.862 13.0647 95.3855 14.644 94.3834 17.1781C93.3813 19.707 93.4757 23.9464 93.4757 26.7954V53.3963C93.4757 54.3984 92.6362 55.2117 91.5921 55.2117H81.828C80.8364 55.1435 80.0651 54.3564 80.0651 53.3963L80.0493 26.7954C80.0493 21.1971 80.9728 12.9598 74.0261 12.9598C67.0794 12.9598 67.2736 20.9925 67.2736 26.7954V53.3963C67.2736 54.3984 66.4341 55.2117 65.39 55.2117"
        fill="#6A488E"
      />
      <path
        d="M246.15 0.482422C260.663 0.482422 268.517 12.9434 268.517 28.7885C268.517 44.6336 259.839 56.2447 246.15 56.2447C232.462 56.2447 224.14 43.7837 224.14 28.2534C224.14 12.723 231.995 0.482422 246.15 0.482422ZM246.234 10.7293C239.025 10.7293 238.574 20.5512 238.574 26.6741C238.574 32.797 238.48 45.9034 246.156 45.9034C253.831 45.9034 254.094 35.3417 254.094 28.904C254.094 24.6698 253.915 19.6067 252.635 15.5878C251.533 12.0934 249.346 10.7345 246.239 10.7345"
        fill="#6A488E"
      />
      <path
        d="M287.332 55.2006H277.594C276.618 55.1323 275.831 54.3453 275.831 53.3852L275.815 3.17924C275.899 2.25582 276.707 1.54226 277.699 1.54226H286.765C287.62 1.58423 288.318 2.16138 288.512 2.94314V10.6191H288.691C291.43 3.75638 295.265 0.482422 302.017 0.482422C306.404 0.482422 310.685 2.06169 313.434 6.39548C315.995 10.4145 315.995 17.167 315.995 22.0202V53.616C315.885 54.4975 315.071 55.1953 314.111 55.1953H304.305C303.413 55.1271 302.668 54.466 302.574 53.616V26.354C302.574 20.866 303.208 12.8332 296.451 12.8332C294.069 12.8332 291.886 14.4282 290.795 16.8522C289.42 19.9216 289.242 22.9751 289.242 26.3593V53.3904C289.226 54.3926 288.376 55.2058 287.332 55.2058"
        fill="#6A488E"
      />
      <path
        d="M157.029 31.223V29.1034C149.957 29.1034 142.491 30.6144 142.491 38.9515C142.491 43.1751 144.679 46.0345 148.435 46.0345C151.184 46.0345 153.65 44.3451 155.203 41.5906C157.129 38.2064 157.035 35.0269 157.035 31.2178M166.898 55.0537C166.253 55.6308 165.319 55.6728 164.59 55.2898C161.342 52.593 160.765 51.3442 158.976 48.7681C153.614 54.2404 149.815 55.8774 142.853 55.8774C134.626 55.8774 128.22 50.8038 128.22 40.6357C128.22 32.6974 132.527 27.2932 138.645 24.6541C143.954 22.3141 151.368 21.9048 157.035 21.2542V19.9898C157.035 17.6655 157.213 14.9162 155.854 12.9067C154.658 11.1071 152.375 10.362 150.366 10.362C146.641 10.362 143.309 12.2718 142.496 16.2331C142.333 17.1146 141.683 17.9803 140.806 18.0223L131.315 17.0044C130.518 16.826 129.636 16.1807 129.857 14.9529C132.044 3.4521 142.428 -0.0107422 151.725 -0.0107422C156.484 -0.0107422 162.701 1.25372 166.458 4.85822C171.217 9.3022 170.765 15.231 170.765 21.6792V36.921C170.765 41.5014 172.665 43.5109 174.454 45.9873C175.089 46.8688 175.225 47.9286 174.428 48.5845C172.434 50.2477 168.882 53.3432 166.93 55.0747L166.904 55.0484"
        fill="#6A488E"
      />
      <path
        d="M28.8154 31.2233V29.1036C21.7428 29.1036 14.2767 30.6147 14.2767 38.9517C14.2767 43.1753 16.4646 46.0348 20.2212 46.0348C22.9705 46.0348 25.4365 44.3453 26.9895 41.5908C28.9151 38.2067 28.8206 35.0272 28.8206 31.218M38.6845 55.0539C38.0391 55.6311 37.1052 55.673 36.3759 55.29C33.1282 52.5932 32.551 51.3445 30.7619 48.7683C25.3998 54.2407 21.6011 55.8777 14.6387 55.8777C6.41184 55.8777 0.00558472 50.8041 0.00558472 40.6359C0.00558472 32.6976 4.31315 27.2935 10.4308 24.6544C15.7405 22.3143 23.1542 21.9051 28.8206 21.2545V19.99C28.8206 17.6657 28.999 14.9164 27.6401 12.907C26.4439 11.1073 24.1615 10.3623 22.152 10.3623C18.4269 10.3623 15.0952 12.2721 14.2819 16.2334C14.1193 17.1148 13.4687 17.9805 12.5925 18.0225L3.10115 17.0046C2.30365 16.8263 1.4222 16.1809 1.64256 14.9532C3.8252 3.46809 14.2085 0 23.5057 0C28.2645 0 34.4818 1.26446 38.2385 4.86896C42.9973 9.31294 42.5461 15.2417 42.5461 21.69V36.9317C42.5461 41.5121 44.4454 43.5216 46.2345 45.9981C46.8694 46.8795 47.0058 47.9394 46.2083 48.5952C44.2145 50.2584 40.6625 53.354 38.7107 55.0854L38.6845 55.0592"
        fill="#6A488E"
      />
    </SvgIcon>
  );
};

export default AmazonIcon;
