import { useTranslations } from "next-intl";
import { useForm, Controller, Form } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useEffect, useState, forwardRef } from "react";

import styles from "@/components/SignUpForm/signUpForm.module.scss";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";

import CheckmarkIcon from "@/components/MuiIcons/CheckmarkIcon";

interface RepoType {
  email: string;
  repo: string;
  message: string;
  robot?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignUpForm = () => {
  const t = useTranslations("SignUpForm");
  const errorMessages = {
    url: "Please enter a valid GitHub URL",
    invalidBounty: "Please enter a valid bounty amount",
  };
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const github_issue_regex = new RegExp("https://github.com/[^/]+/[^/]+/", "g");
  const emailschema = z.object({
    email: z.string().email("Invalid email address"),
    repo: z.optional(
      z.string().regex(github_issue_regex, {
        message: errorMessages.url,
      })
    ),
    message: z.optional(z.string()),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<RepoType>({
    resolver: zodResolver(emailschema),
    defaultValues: {},
  });

  useEffect(() => {}, []);

  async function onSubmit(data: RepoType) {
    try {
      const email_response: any = await axios.post(
        "/api/internalnotification/",
        { ...data, contact_type: "repo" }
      );
      console.log("Data: ", data);
      if (email_response.status === 200) {
        setSubmitted(true);
        setDialogOpen(true);
      } else {
        setSubmitError(email_response?.message);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <>
      <Paper
        sx={{
          maxWidth: "fit-content",
          padding: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {!submitted ? (
          <form
            className={styles.createBountyForm}
            onSubmit={handleSubmit(onSubmit)}
            aria-describedby="signup-for-newsletter-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div style={{ display: "none" }}>
              <Controller
                name="robot"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <InputLabel htmlFor="bounty">
                      Please fill out this field
                    </InputLabel>
                    <Input
                      id="amount"
                      aria-describedby={"signup-important-field"}
                      type="text"
                      error={error ? true : false}
                      {...field}
                      placeholder="Please fill out this field"
                      value={field.value}
                      hidden
                      color="secondary"
                    />
                    {errors.robot && (
                      <Alert severity="error">{errors.robot.message}</Alert>
                    )}
                  </FormControl>
                )}
              />
            </div>
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <TextField
                    id="message"
                    label={t("message-input-label")}
                    multiline
                    rows={8}
                    variant="standard"
                    error={error ? true : false}
                    {...field}
                    placeholder="I am interested in..."
                    value={field.value ? field.value : ""}
                    color="secondary"
                  />
                  {errors.message && (
                    <Alert severity="error">{errors.message.message}</Alert>
                  )}
                  {submitError && <Alert severity="error">{submitError}</Alert>}
                </FormControl>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <InputLabel htmlFor="email">
                    {t("email-input-label")}
                  </InputLabel>
                  <Input
                    id="email"
                    aria-describedby={"signup-email-field"}
                    type="email"
                    error={error ? true : false}
                    {...field}
                    placeholder="max.mustermann@hydraoss.io"
                    value={field.value ? field.value : ""}
                    color="secondary"
                  />
                  {errors.email && (
                    <Alert severity="error">{errors.email.message}</Alert>
                  )}
                  {submitError && <Alert severity="error">{submitError}</Alert>}
                </FormControl>
              )}
            />
            {/* Rahul Temporarily requested its removal
            <Controller
              name="repo"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <InputLabel htmlFor="repo">
                    {t("repo-input-label")}
                  </InputLabel>
                  <Input
                    id="repo"
                    aria-describedby="signup-repo-field"
                    type="repo"
                    error={error ? true : false}
                    {...field}
                    placeholder="https://github.com/OpenHydra1"
                    value={field.value ? field.value : ""}
                    color="secondary"
                  />
                  {errors.email && (
                    <Alert severity="error">{errors.email.message}</Alert>
                  )}
                  {submitError && <Alert severity="error">{submitError}</Alert>}
                </FormControl>
              )}
            /> */}
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              aria-describedby="signup-for-newsletter-button"
              autoFocus={false}
              sx={{
                maxWidth: "fit-content",
                alignSelf: "flex-end",
                justifySelf: "center",
                marginTop: "2rem",
              }}
            >
              {t("button-contact")}
            </Button>
          </form>
        ) : (
          <div className="w-full flex flex-col items-center justify-center">
            <CheckmarkIcon sx={{ width: "4rem", height: "4rem" }} />
          </div>
        )}
      </Paper>
      {/* Temporarily requested its removal
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDialogOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle>{t("submitted-title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("submitted-description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Got it</Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
SignUpForm.displayName = "SignUpForm";
export default SignUpForm;
