import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "ServiceIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})(() => ({
  fill: "none",
  stroke: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
}));

const ServiceIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64" focusable="false">
      <g
        stroke="#FDF9ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_517_1717)"
      >
        <path d="M25.143 18.286v6.857M38.857 18.286v6.857M25.143 34.286c0 6.08 13.714 6.08 13.714 0M13.714 32V19.977A17.966 17.966 0 0132 2.287a17.965 17.965 0 0118.286 17.69V32M6.857 25.144h4.572a2.286 2.286 0 012.285 2.285v13.715a2.285 2.285 0 01-2.285 2.285H6.857a4.572 4.572 0 01-4.571-4.571v-9.143a4.571 4.571 0 014.571-4.571z"></path>
        <path d="M57.143 43.43H52.57a2.287 2.287 0 01-2.285-2.286V27.429a2.287 2.287 0 012.285-2.285h4.572a4.571 4.571 0 014.571 4.571v9.143a4.572 4.572 0 01-4.571 4.571z"></path>
        <path d="M41.143 56a9.142 9.142 0 009.143-9.143V36.571M41.143 56a5.715 5.715 0 01-5.714 5.715H28.57a5.715 5.715 0 010-11.429h6.858A5.715 5.715 0 0141.143 56z"></path>
      </g>
      <defs>
        <clipPath id="clip0_517_1717">
          <path fill="#fff" d="M0 0H64V64H0z"></path>
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ServiceIcon;
